<template>
	<div>
		<a-popover placement="right">
			<template slot="content">
			  <sketch-picker class="select-color" @input="updateValue" :value="value"></sketch-picker>
			</template>
			<div class="flex alcenter">
				<span>{{title}}:</span><span class="select-color-btn ml5" :style="{background:value}"></span>
			</div>
		</a-popover>		
	</div>
</template>

<script>
	import {Sketch as sketchPicker}   from 'vue-color';
	export default{
		components:{
			sketchPicker
		},
		props:{
			value:{
				type:String,
				default:'',
			},
			type:{
				type:String,
				default:'hex',
			},
			title:{
				type:String,
				default:'主色调'
			}
		},
		data(){
			return {
				
			}
		},
		methods:{
			updateValue(e){
				console.log(e);
				if(this.type == 'hex'){
					this.$emit('input',e.hex);
				}else if(this.type == 'hex8'){
					this.$emit('input',e.hex8);
				}else{
					let rgba = 'rgba('+e.rgba.r+','+e.rgba.g+','+e.rgba.b+','+e.rgba.a+')';
					this.$emit('input',rgba);
				}
			}
		}
	}
</script>

<style>
	.ant-popover-inner-content{
		padding: 5px;
	}
	.select-color.vc-sketch{
		box-shadow: none;
		border-radius: 0px;
	}
	.select-color-btn{
		width: 20px;
		height: 20px;
		border:1px solid #000000;
	}
</style>